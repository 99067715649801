import React, { useState, useEffect } from 'react'
import { Button, Input, Container, CircularProgress, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import localStorage from 'localStorage'
import SnackMessage from '../components/snackbar'
import Nav from '../components/nav'
import { post } from '../utils/request'

const FindPassword = () => {
  const [loading, setLoading] = useState(false)
  const [findPasswordEmailOrMobile, setFindPasswordEmailOrMobile] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const [codeSended, setcodeSended] = useState(false)
  const onInputChange = (field, v) => {
    switch (field) {
      case 'verificationCode':
        setVerificationCode(v)
        break
      case 'password':
        setPassword(v)
        break
      case 'passwordConfirmation':
        setPasswordConfirmation(v)
        break
      default:
    }
  }
  const input = (field, hintText, type) => <Input type={type || 'text'} className="mt-6" placeholder={hintText} fullWidth onChange={(e) => onInputChange(field, e.target.value)} />
  const sendFindPasswordCode = async (params) => {
    const res = await post('managers/passwords/send_find_password_code', params)
    if (res.ok) {
      setcodeSended(true)
    }
    setOpen(true)
    setResMessage(res.message)
  }
  const findPassword = async (params) => {
    const res = await post('managers/passwords/reset_password_by_code', params)
    if (res.ok) {
      navigate('/')
    }
    setOpen(true)
    setResMessage(res.message)
    setLoading(false)
  }
  useEffect(() => {
    localStorage.setItem('DEVICE_TYPE', 'WEB_MANAGER')
    if (localStorage.getItem('Token')) {
      navigate('/')
    }
  })
  return (
    <Nav>
      <Container fixed maxWidth="xs">
        <Grid container>
          <Grid item xs className="pt-32">
            <Input placeholder="管理员账号" type="text" autoFocus fullWidth value={findPasswordEmailOrMobile} onChange={(e) => setFindPasswordEmailOrMobile(e.target.value)} />
            <Input
              type="text"
              placeholder="验证码"
              fullWidth
              className="mt-6"
              onChange={(e) => onInputChange('verificationCode', e.target.value)}
              endAdornment={
                <Button color="primary" variant="contained" className="w-1/3" onClick={() => sendFindPasswordCode({ email_or_mobile: findPasswordEmailOrMobile })}>
                  {codeSended ? '已发送' : '发送'}
                </Button>
              }
            />
            {input('password', '密码', 'password', 'lock')}
            {input('passwordConfirmation', '确认密码', 'password', 'lock')}
            <Button
              className="mt-8"
              color="primary"
              variant="contained"
              disabled={loading}
              fullWidth
              onClick={() => {
                setLoading(true)
                findPassword({ email_or_mobile: findPasswordEmailOrMobile, code: verificationCode, password, password_confirmation: passwordConfirmation })
              }}
            >
              {loading ? <CircularProgress size={24} /> : '确定'}
            </Button>
          </Grid>
        </Grid>
        <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
      </Container>
    </Nav>
  )
}
export default FindPassword
